import * as React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "../components/Layout";
import { Hero } from "../components/Hero";
import { filterHtml } from "../utils/filterHtml";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Slider from "react-slick";
import { Image } from "../components/Slices/Image";
import { PropTypes } from "prop-types";
import { Profile } from "../components/Profile";
import { GatsbyLink } from "../components/Slices/GatsbyLink";
import { NavigationBlock } from "../components/NavigationBlock";
import { EventTeasers } from "../components/EventTeasers";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// markup
const HomePage = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;
  const homepage = data.prismicHome || {};
  const showEvents = false;
  const desc = homepage.data.meta_description;
  const { lang, type, url } = homepage || {};
  const alternateLanguages = homepage.alternate_languages || [];
  const title = homepage.data.title.text || "";
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    desc,
    title,
  };

  const settings = {
    dots: true,
    buttons: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1364,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Layout activeDocMeta={activeDoc} hideBreadcrumb>
      <Hero title={homepage.data.title.text}>
        <div
          dangerouslySetInnerHTML={{
            __html: filterHtml(homepage.data.intro.html),
          }}
        ></div>
      </Hero>

      <Profile />

      {showEvents && (
        <div className="mb-xxl mb-l@screen u-mb0@tablet">
          <EventTeasers
            display="big"
            title={t("Welke community activiteiten kan je verwachten?")}
            time="Coming"
            showPodcast
          />
        </div>
      )}

      {homepage.data.show_podcast_banner && (
        <div className="o-stretch-wrapper u-bg-blue-100">
          <div className="o-wrapper -spacing-y-0 c-podcast-banner">
            <div className="o-1-col c-podcast-banner__inner">
              <Image
                className="c-podcast-banner__image"
                image={homepage.data.podcast_image}
              />
              <h2>{homepage.data.podcast_title.text}</h2>
              <div
                className="o-intro-text"
                dangerouslySetInnerHTML={{
                  __html: filterHtml(homepage.data.podcast_description.html),
                }}
              />
              <div className="o-btn-group">
                <GatsbyLink
                  link={homepage.data.podcast_button_link}
                  label={homepage.data.podcast_button_label}
                  className="c-btn -primary"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <NavigationBlock
        image={homepage.data.banner_image}
        title={t("Aan de slag met een plan")}
        items={data.allPrismicTheme.nodes
          .filter((value) => !value.data.homepage_verdiep)
          .concat(data.allPrismicDownloads.nodes)}
      />
			
      <NavigationBlock
        title={t("Verdiep je in volgende thema's")}
        items={data.allPrismicTheme.nodes.filter(
          (value) => value.data.homepage_verdiep
        )}
      />

      {homepage.data.event_show_banner && (
        <div className="o-stretch-wrapper">
          <div className="c-event-banner o-wrapper">
            <div className="o-2-col gap-s@mobile -sidearea -sidearea-below  c-event-banner-block ">
              <div className="c-event-banner-block__imageblock ">
                <Image
                  key={homepage.data.event_banner_image.id}
                  className="c-event-banner-block__image desktop"
                  image={homepage.data.event_banner_image}
                />
                <Image
                  key={homepage.data.event_banner_image_mobile.id}
                  className="c-event-banner-block__image mobile"
                  image={homepage.data.event_banner_image_mobile}
                />
              </div>
              <div className="c-event-banner-block__textblock is-sidearea">
                <h5 className="h4 in-de-kijker">
                  <span>{t("In de kijker")}</span>
                </h5>
                <h4>{homepage.data.event_banner_title.text}</h4>
                <div
                  className="c-event-banner-block__body"
                  dangerouslySetInnerHTML={{
                    __html: filterHtml(homepage.data.event_banner_body.html),
                  }}
                />
                <div className="o-btn-group">
                  <GatsbyLink
                    link={homepage.data.event_banner_button}
                    label={homepage.data.event_banner_button_label}
                    className="c-btn -secundary -full-width@mobile -large"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="o-stretch-wrapper" id="educam">
        <div className="c-rubriek o-wrapper">
          <div className="o-2-col c-rubriek-block ">
            <div className="c-rubriek-block__textblock">
              <h2 className="h2">{homepage.data.video_title.text}</h2>
              <div
                className="o-intro-text"
                dangerouslySetInnerHTML={{
                  __html: filterHtml(homepage.data.video_text.html),
                }}
              />
              {homepage.data.video_cta_link &&
                homepage.data.video_cta_label && (
                  <div className="o-btn-group">
                    <GatsbyLink
                      link={homepage.data.video_cta_link}
                      label={homepage.data.video_cta_label}
                      className="c-btn -primary"
                    />
                  </div>
                )}
            </div>
            <div className="c-rubriek-block__videoblock__wrapper">
              <div
                className="c-rubriek-block__videoblock"
                dangerouslySetInnerHTML={{
                  __html: filterHtml(homepage.data.video.html),
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="o-stretch-wrapper u-bg-blue-100 c-cases-banner__wrapper">
        <div className="c-cases-banner o-wrapper -spacing-y-0">
          <div className="o-2-col gap-xxl -sidearea-left  c-cases-banner">
            <div className="c-cases-banner__textblock">
              <h2 className="h1">{homepage.data.banner_bottom_title.text}</h2>
              <div
                className="o-intro-text"
                dangerouslySetInnerHTML={{
                  __html: filterHtml(homepage.data.banner_bottom_text.html),
                }}
              />
              {homepage.data.banner_bottom_cta_link &&
                homepage.data.banner_bottom_cta_label && (
                  <div className="o-btn-group">
                    <GatsbyLink
                      link={homepage.data.banner_bottom_cta_link}
                      label={homepage.data.banner_bottom_cta_label}
                      className="c-btn -primary"
                    />
                  </div>
                )}
            </div>
            <div className="c-cases-banner__imageblock is-sidearea">
              <Image
                key={homepage.data.banner_bottom_image.id}
                className="c-cases-banner__image"
                image={homepage.data.banner_bottom_image}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="o-stretch-wrapper u-bg-blue-100-50 c-carousel__wrapper">
        <div className="c-carousel o-wrapper -spacing-y-0">
          <div className="c-carousel__title">
            <h3 className="h3">{homepage.data.cases_title.text}</h3>
          </div>
          <div className="c-carousel__slider">
            <Slider {...settings}>
              {homepage.data.case_studies.map((value) => {
                const case_study = value.case_field.document;
                return (
                  <div className="c-carousel__item-wrapper" key={case_study.id}>
                    <article className="c-carousel__item">
                      <div className="c-carousel__item__image">
                        <Image
                          key={case_study.data.preview_image.id}
                          image={case_study.data.preview_image}
                        />
                      </div>
                      <h4 className="c-carousel__item__title">
                        <Link
                          to={case_study.url}
                          state={{ from: case_study.id }}
                        >
                          {case_study.data.title.text}
                        </Link>
                      </h4>
                      <div className="c-carousel__item__promotor">
                        {case_study.data.promotor}
                      </div>
                      <div
                        className="c-carousel__item__content"
                        dangerouslySetInnerHTML={{
                          __html: filterHtml(case_study.data.abstract.html),
                        }}
                      />
                    </article>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>

      <div className="o-stretch-wrapper">
        <div className="o-wrapper -spacing-y-0">
          <div className="o-btn-group u-justify-content-center">
            {homepage.data.banner_bottom_cta2_link &&
              homepage.data.banner_bottom_cta2_label && (
                <GatsbyLink
                  link={homepage.data.banner_bottom_cta2_link}
                  label={homepage.data.banner_bottom_cta2_label}
                  className="c-btn -tertiary"
                />
              )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  fragment caseStudyInfo on PrismicCaseStudy {
    _previewable
    id
    url
    data {
      title {
        text
      }
      abstract {
        html
      }
      promotor
      preview_image {
        url
        alt
        gatsbyImageData(width: 768, layout: CONSTRAINED, placeholder: NONE)
        localFile {
          childImageSharp {
            gatsbyImageData(width: 768, layout: CONSTRAINED, placeholder: NONE)
          }
          publicURL
        }
        thumbnails {
          mobile {
            url
            gatsbyImageData(width: 1200, layout: CONSTRAINED, placeholder: NONE)
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
              publicURL
            }
          }
        }
      }
      tag_profiles {
        profile {
          uid
          document {
            ... on PrismicVisitorProfile {
              id
              data {
                name
              }
            }
          }
        }
      }
    }
  }
  query HomeQuery($lang: String, $locale: String) {
    prismicHome(lang: { eq: $lang }) {
      _previewable
      type
      alternate_languages {
        document {
          ... on PrismicLevel2 {
            url
          }
          ... on PrismicTheme {
            url
          }
          ... on PrismicHome {
            url
          }
          ... on PrismicLevel3Faq {
            url
          }
          ... on PrismicLevel3Step {
            url
          }
          ... on PrismicDisclaimer {
            url
          }
          ... on PrismicPrivacy {
            url
          }
        }
        lang
      }
      lang
      url
      data {
        title {
          text
        }
        meta_description
        intro {
          html
        }
        banner_cta_link {
          url
          localFile {
            publicURL
          }
          link_type
        }
        banner_cta_label
        banner_cta2_link {
          url
          localFile {
            publicURL
          }
          link_type
        }
        banner_cta2_label
        banner_image {
          url
          alt
          gatsbyImageData(width: 900, layout: CONSTRAINED, placeholder: NONE)
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 900
                layout: CONSTRAINED
                placeholder: NONE
              )
            }
            publicURL
          }
          thumbnails {
            mobile {
              url
              gatsbyImageData(
                width: 1230
                layout: CONSTRAINED
                placeholder: NONE
              )
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1230
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
                publicURL
              }
            }
          }
        }
        banner_title {
          text
        }
        banner_text {
          html
        }
        banner_bottom_cta_link {
          url
          localFile {
            publicURL
          }
          link_type
        }
        banner_bottom_cta_label
        banner_bottom_cta2_link {
          url
          localFile {
            publicURL
          }
          link_type
        }
        banner_bottom_cta2_label
        banner_bottom_image {
          url
          alt
          gatsbyImageData(width: 900, layout: CONSTRAINED, placeholder: NONE)
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 900
                layout: CONSTRAINED
                placeholder: NONE
              )
            }
            publicURL
          }
          thumbnails {
            mobile {
              url
              gatsbyImageData(
                width: 1230
                layout: CONSTRAINED
                placeholder: NONE
              )
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1230
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
                publicURL
              }
            }
          }
        }
        banner_bottom_title {
          text
        }
        banner_bottom_text {
          html
        }
        video {
          html
        }
        video_cta_label
        video_cta_link {
          url
          localFile {
            publicURL
          }
          link_type
        }
        video_text {
          html
        }
        video_title {
          text
        }
        case_studies {
          case_field {
            document {
              ... on PrismicCaseStudy {
                ...caseStudyInfo
              }
            }
          }
        }
        cases_title {
          text
        }
        event_banner_image {
          url
          alt
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
            publicURL
          }
        }
        event_banner_image_mobile {
          url
          alt
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
            publicURL
          }
        }
        event_banner_title {
          text
        }
        event_banner_body {
          html
        }
        event_banner_button {
          url
          localFile {
            publicURL
          }
          link_type
        }
        event_banner_button_label
        event_show_banner
        podcast_image {
          url
          alt
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
            publicURL
          }
        }
        podcast_title {
          text
        }
        podcast_description {
          html
        }
        podcast_button_link {
          url
          localFile {
            publicURL
          }
          link_type
        }
        podcast_button_label
        show_podcast_banner
      }
    }
    allPrismicTheme(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___weight, order: ASC }
    ) {
      nodes {
        _previewable
        id
        data {
          title {
            text
          }
          abstract {
            text
          }
          homepage_verdiep
        }
        prismicId
        url
      }
    }
    allPrismicDownloads(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        id
        data {
          title {
            text
          }
          abstract {
            text
          }
        }
        prismicId
        url
      }
    }
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withPrismicPreview(HomePage);
