import * as React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "../components/Layout";
import { PropTypes } from "prop-types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Welcome } from "../components/Welcome";
import { filterLearningOverviewContent } from "../utils/filterContent";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const LearningOverview = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const page = data.prismicLearningOverview || {};
  const { lang, type, url } = page || {};

  const title = page.data.title.text;

  const alternateLanguages = page.alternate_languages || [];
  const parents = [
    {
      url: page.data.parent.url,
      label: page.data.parent?.document?.data?.title?.text,
    },
  ];
  const activeDoc = { lang, type, url, alternateLanguages, title, parents };

  const iconList = {
    Video: "computer",
    Info: "article",
    Podcast: "podcast",
  };
  const singleLevel = page.data.body.length === 1;

  const otherCourses = filterLearningOverviewContent(
    data.allPrismicLearningOverview.nodes
  );

  return (
    <Layout activeDocMeta={activeDoc}>
      <div className="o-wrapper o-2-col -sidearea">
        <div>
          <div className="c-intro">
            <Welcome page={page} />
          </div>
          <div className="c-chapters">
            {page.data.body.map((chapter, chapterIndex) => {
              return (
                <div className={`c-chapter`} key={chapter.id}>
                  {chapter.primary?.title?.text !== "" && (
                    <h3>
                      {!singleLevel && <span>{chapterIndex + 1}. </span>}
                      <span>{chapter.primary?.title?.text}</span>
                    </h3>
                  )}
                  <div className="c-chapter__list">
                    {chapter.items.map((lesson, lessonIndex) => {
                      return (
                        <div
                          className={`c-chapter__lesson ${
                            lesson.accessible ? "" : "not-accessible"
                          }`}
                          key={lesson.lesson_page.id}
                        >
                          <Link
                            to={
                              lesson.accessible
                                ? lesson.lesson_page.document.url
                                : "#"
                            }
                            className="c-chapter__lesson-title"
                          >
                            <span
                              className={`icon-${
                                iconList[lesson.lesson_page.document.data.type]
                              }`}
                            ></span>
                            <div className="text">
                              <span>
                                {(!singleLevel ? chapterIndex + 1 + "." : "") +
                                  (lessonIndex + 1) +
                                  ". "}
                              </span>
                              <span>
                                {lesson.lesson_page.document.data.title.text}
                              </span>
                              <span>
                                {" "}
                                {!lesson.accessible &&
                                  t("(binnenkort beschikbaar)")}
                              </span>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {otherCourses.length > 0 && (
          <div className="c-sidearea">
            <div className="c-info-block">
              <div className="c-info-block__container">
                <h3>{t("Andere e-learning cursussen")}</h3>
                <div className="c-info-block__content">
                  <ul>
                    {otherCourses.filter((value) => (value.id !== page.id)).map((value) => (
                      <li key={value.id}>
                        <Link to={value.url + "#welkom"}>
                          {value.data.title.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

LearningOverview.propTypes = {
  data: PropTypes.object
};

export default withPrismicPreview(LearningOverview);

export const query = graphql`
  query learningOverviewQuery($lang: String, $id: String, $locale: String) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicLearningOverview(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      data {
        body {
          ... on PrismicLearningOverviewDataBodyChapter {
            id
            items {
              accessible
              lesson_page {
                id
                document {
                  ... on PrismicLearningDetail {
                    id
                    url
                    data {
                      title {
                        text
                      }
                      type
                    }
                  }
                }
              }
            }
            primary {
              title {
                text
              }
            }
          }
        }
        parent {
          id
          url
          document {
            ... on PrismicTheme {
              id
              data {
                title {
                  text
                }
              }
            }
          }
        }
        title {
          text
        }
        title1 {
          text
        }
        description {
          html
        }
        image {
          alt
          url
          gatsbyImageData
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        welcome_video {
          id
          html
        }
      }
      type
      url
      id
      alternate_languages {
        document {
          ... on PrismicLearningOverview {
            url
          }
        }
        lang
      }
      lang
    }
    allPrismicLearningOverview {
      nodes {
        _previewable
        url
        id
        data {
          title {
            text
          }
          title1 {
            text
          }
          body {
            ... on PrismicLearningOverviewDataBodyChapter {
              id
              items {
                accessible
              }
            }
          }
        }
      }
    }
  }
`;
