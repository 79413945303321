import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { SliceZone } from "../components/SliceZone";
import { useTranslation } from "gatsby-plugin-react-i18next";
import slugify from "react-slugify";
import { filterHtml } from "../utils/filterHtml";
import { PropTypes } from "prop-types";
import { Hero } from "../components/Hero";
import { GatsbyLink } from "../components/Slices/GatsbyLink";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Editorial = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const page = data.prismicEditorial || {};
  const { lang, type, url } = page || {};

  const relatedPages = Array.isArray(page.data.related_pages)
    ? page.data.related_pages.filter((page) => page.title1.text.length > 0)
    : [];
  const title = page.data.title.text;
  const desc = page.data.meta_description;

  const alternateLanguages = page.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    title,
    desc,
  };

  let colClass;
  switch (page.data.related_pages.length) {
    case 2:
      colClass = "o-2-col";
      break;
    case 3:
      colClass = "o-3-col";
      break;
    default:
      colClass = "";
  }
  let tocItems = [];
  for (const value of page.data.body) {
    if (value.primary.title1?.text) {
      tocItems.push(value.primary.title1.text);
    }
  }

  return (
    <Layout activeDocMeta={activeDoc} hideBreadcrumb>
      {page.data.display_hero && (
        <Hero
          title={page.data.title.text}
          showImage={typeof page.data.hero_image == "object"}
          background={page.data.dark_background ? "-event" : "-light-blue-100 -editorial"}
          image={page.data.hero_image}
          breadcrumbs={activeDoc}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: filterHtml(page.data.intro.html),
            }}
          ></div>
        </Hero>
      )}

      {!page.data.display_hero && (
        <div className="o-wrapper c-intro">
          <div className="o-1-col">
            <h1 className="c-intro-block__title">{page.data.title.text}</h1>
            <div
              className="o-intro-text"
              dangerouslySetInnerHTML={{
                __html: filterHtml(page.data.intro.html),
              }}
            />
          </div>
        </div>
      )}

      <div className={`o-wrapper ${!page.data.hide_sidebar && "o-2-col -sidearea"}`}>
        <div>
          <SliceZone slices={page.data.body} />
        </div>
        {!page.data.hide_sidebar && <div className="is-sidearea">
          <div className="c-sidearea">
            <div className="c-sticky-block">
              <div className="c-sticky-block__container">
                {page.data.show_table_of_contents && tocItems.length > 0 && (
                  <div className="toc-block">
                    <h3>{t("toc")}</h3>
                    <ul>
                      {tocItems.map((value) => {
                        return (
                          <li key={"toc-" + value}>
                            <a href={"#" + slugify(value)}>{value}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {page.data.sidebar_title?.text !== "" && <div className={`c-info-block c-sticky-block`}>
                  <div className={`c-info-block__container c-sticky-block__container`}>
                    <div className="c-info-block__content">
                      <h3 className="c-info-block__title">{page.data.sidebar_title.text}</h3>
                      <div className="c-info-block__description " dangerouslySetInnerHTML={{__html: filterHtml(page.data.sidebar_body.html)}}/>
                      <GatsbyLink className="c-btn -primary -full-width -big" link={page.data.button_link} label={page.data.button_label} />
                    </div>
                  </div>
                </div>}
                {!page.data.show_table_of_contents && page.data.sidebar_title?.text === "" && <a className="back-to-top__link" href="#content">
                  {t('Scroll back to top')}
                </a>}
              </div>
            </div>
          </div>
        </div>
      }      </div>
      {relatedPages.length > 0 && (
        <div className="o-wrapper related related-pages u-bg-grey-100">
          {page.data.related_title.text && (
            <h2>{page.data.related_title.text}</h2>
          )}
          <div className={colClass}>
            {relatedPages.map((value) => {
              return (
                <div className="related-page" key={value}>
                  {value.title1.text && <h3>{value.title1.text}</h3>}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: filterHtml(value.text.html),
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Layout>
  );
};

Editorial.propTypes = {
  data: PropTypes.object,
};

export default withPrismicPreview(Editorial);

export const query = graphql`
  query EditorialQuery($lang: String, $id: String, $locale: String) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicEditorial(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      data {
        body {
          ... on PrismicEditorialDataBodyImage {
            id
            primary {
              image {
                alt
                url
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: NONE
                )
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                  publicURL
                }
                thumbnails {
                  mobile {
                    url
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: NONE
                        )
                      }
                      publicURL
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicEditorialDataBodyDownload {
            id
            slice_type
            primary {
              file {
                url
                size
                localFile {
                  extension
                  publicURL
                  size
                }
              }
              label
            }
          }
          ... on PrismicEditorialDataBodyQuote {
            id
            primary {
              author
              image {
                alt
                url
                localFile {
                  publicURL
                }
              }
              quote {
                html
              }
            }
            slice_type
          }
          ... on PrismicEditorialDataBodyTextblock {
            id
            primary {
              text {
                html
              }
              title1 {
                text
              }
            }
            slice_type
          }
          ... on PrismicEditorialDataBodyEventList {
            id
            primary {
              type
              time
              heading
            }
            slice_type
          }
        }
        meta_description
        intro {
          html
        }
        hide_sidebar
        related_pages {
          text {
            html
          }
          title1 {
            text
          }
        }
        related_title {
          text
        }
        show_table_of_contents
        title {
          text
        }
        button_label
        button_link {
          url
          localFile {
            publicURL
          }
          link_type
          target
        }
        sidebar_body {
          html
        }
        sidebar_title {
          text
        }
        display_hero
        dark_background
        hero_image {
          alt
          url
          localFile {
            publicURL
          }
        }
      }
      type
      url
      alternate_languages {
        document {
          ... on PrismicEditorial {
            url
          }
        }
        lang
      }
      lang
    }
  }
`;
