import * as React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "../components/Layout";
import { PropTypes } from "prop-types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { BlogPopup } from "../components/Slices/BlogPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const LearningDetail = ({ data }) => {
  const { t } = useTranslation();
  const [dropdown, setDropdown] = React.useState("down");
  const [dropdownLength, setDropdownLength] = React.useState("0px");
  const [iframeLength, setIframeLength] = React.useState("0px");
  const page = data.prismicLearningDetail;

  const { lang, type, url } = page || {};
  const title = page.data.title.text;
  const desc = page.data.meta_description;
  const alternateLanguages = page.alternate_languages || [];
  const parents = [
    {
      url: page.data.parent.document.data.parent.url,
      label: page.data.parent.document.data.parent.document.data.title.text,
    },
    {
      url: page.data.parent.url,
      label: page.data.parent.document.data.title.text,
    },
  ];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    title,
    desc,
    parents,
  };

  const toggleDropdown = () => {
    let button = document.querySelector(".field-dropdown");
    setDropdownLength(
      window.innerHeight -
        button.getBoundingClientRect().top -
        button.clientHeight +
        "px"
    );
    setDropdown(dropdown === "up" ? "down" : "up");
  };

  const ref = React.useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdown("down");
    }
  };
  const handleResize = () => {
    setIframeLength(
      window.innerHeight -
        document.querySelector(".region-header")?.clientHeight +
        "px"
    );
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    window.addEventListener("resize", handleResize, true);
    window.dispatchEvent(new Event("resize"));
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      window.removeEventListener("resize", handleResize, true);
    };
  });

  let list = [],
    ids = [];
  const singleLevel = page.data?.parent?.document?.data?.body?.length === 1;
  for (let i = 0; i < page.data?.parent?.document?.data?.body?.length; i++) {
    const chapter = page.data?.parent?.document?.data?.body[i];
    for (let j = 0; j < chapter.items.length; j++) {
      const lesson = chapter.items[j];
      ids.push(lesson.lesson_page.document.id);
      list.push({
        id: lesson.lesson_page.document.id,
        number: singleLevel ? `${j + 1}. ` : `${i + 1}.${j + 1}. `,
        content: lesson,
      });
    }
  }

  const currentIndex = ids.indexOf(page.id);
  let prevButton, nextButton;
  if (currentIndex !== 0) {
    prevButton = list[currentIndex - 1];
    prevButton.url = prevButton.content.lesson_page.url;
    prevButton.class = "c-btn prevButton";
    if (!prevButton.content.accessible) {
      prevButton.url = "#";
      prevButton.class += " disabled";
    }
  }
  if (currentIndex !== ids.length - 1) {
    nextButton = list[currentIndex + 1];
    nextButton.url = nextButton.content.lesson_page.url;
    nextButton.class = "c-btn nextButton";
    if (!nextButton.content.accessible) {
      nextButton.url = "#";
      nextButton.class += " disabled";
    }
  }

  return (
    <Layout activeDocMeta={activeDoc}>
      <div className="o-wrapper intro">
        <h1 className="c-intro-block__title">{page.data.title.text}</h1>
      </div>
      <div className="o-wrapper">
        {page.data.body?.length &&
          list[currentIndex].content.accessible &&
          page.data.body.map((slice) => {
            if (slice.slice_type === "embedded_page") {
              return (
                <div key={slice.id} className="c-lesson" style={{ "--iframe-length": iframeLength }}>
                  <iframe src={slice.primary.embed} title="iframe"></iframe>
                </div>
              )
            }
            else if (slice.slice_type === "blog_articles") {
              const otherLinks = slice.items.map((item) => ({
                url: item.blog_article.document.url,
                id: item.blog_article.document.id
              }));
              return (
                <div key={slice.id} className="c-bloglist">
                  {slice.items.map((item, index) => (
                    <BlogPopup
                      otherLinks={otherLinks}
                      index={index+1}
                      key={item.blog_article.id}
                      page={item.blog_article.document}
                    />
                  ))}
                </div>
              );
            }
            return <></>;
        })}
      </div>
      <div className="o-wrapper c-learning-nav">
        {prevButton && (
          <Link to={prevButton.url} className={prevButton.class}>
            {prevButton.number +
              prevButton.content.lesson_page.document.data.title.text}
          </Link>
        )}

        <div className="filter-block" ref={ref}>
          <div className="form-field field-dropdown">
            <div
              className="form-field-icon"
              onClick={() => toggleDropdown()}
              onKeyDown={() => toggleDropdown()}
              role="button"
              tabIndex={0}
            >
              <div className="field-button">{t("Go to lesson")}</div>
              <span className={`field-icon icon-arrow-${dropdown}`} />
            </div>
            <div
              className={`form-field-list list-${dropdown}`}
              style={{ "--dropdown-length": dropdownLength }}
            >
              <div className="nav-link">
                <Link to={page.data.parent.url} className="">
                  {t("Table of content")}
                </Link>
              </div>
              {list.filter((lesson,index) => ( lesson.content.accessible || list[index - 1].content.accessible )).map((lesson) => (
                <div className={`nav-link ${lesson.content.accessible ? "" : "not-accessible"}`} key={"nav-link-" + lesson.id}>
                  <Link to={lesson.content.accessible ? lesson.content.lesson_page.url : "#"}>
                    <span>{lesson.number + lesson.content.lesson_page.document.data.title.text}</span>
                    <span>{" "}{!lesson.content.accessible &&t("(binnenkort beschikbaar)")}</span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        {nextButton && (
          <Link to={nextButton.url} className={nextButton.class}>
            {nextButton.number +
              nextButton.content.lesson_page.document.data.title.text}
          </Link>
        )}
      </div>
    </Layout>
  );
};

LearningDetail.propTypes = {
  data: PropTypes.object,
};

export default withPrismicPreview(LearningDetail);

export const query = graphql`
  query ($id: String, $lang: String!, $locale: String) {
    prismicLearningDetail(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      id
      type
      data {
        parent {
          url
          document {
            ... on PrismicLearningOverview {
              data {
                title {
                  text
                }
                parent {
                  url
                  document {
                    ... on PrismicTheme {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
                body {
                  ... on PrismicLearningOverviewDataBodyChapter {
                    id
                    items {
                      accessible
                      lesson_page {
                        url
                        document {
                          ... on PrismicLearningDetail {
                            id
                            data {
                              title {
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        title {
          text
        }
        type
        body {
          ... on PrismicLearningDetailDataBodyEmbeddedPage {
            id
            slice_type
            primary {
              embed
            }
          }
          ... on PrismicLearningDetailDataBodyBlogArticles {
            id
            slice_type
            items {
              blog_article {
                id
                document {
                  ... on PrismicLevel2 {
                    id
                    url
                    last_publication_date
                    data {
                      intro {
                        html
                      }
                      title {
                        text
                      }
                      abstract {
                        html
                      }
                      body {
                        ... on PrismicLevel2DataBodyImage {
                          id
                          primary {
                            image {
                              alt
                              url
                              gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: BLURRED
                              )
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(
                                    layout: CONSTRAINED
                                    placeholder: BLURRED
                                  )
                                }
                                publicURL
                              }
                              thumbnails {
                                mobile {
                                  url
                                  gatsbyImageData(
                                    layout: CONSTRAINED
                                    placeholder: BLURRED
                                  )
                                  localFile {
                                    childImageSharp {
                                      gatsbyImageData(
                                        layout: CONSTRAINED
                                        placeholder: BLURRED
                                      )
                                    }
                                    publicURL
                                  }
                                }
                              }
                            }
                          }
                          slice_type
                        }
                        ... on PrismicLevel2DataBodyDownload {
                          id
                          slice_type
                          primary {
                            file {
                              url
                              size
                              localFile {
                                extension
                                publicURL
                                size
                              }
                            }
                            label
                          }
                        }
                        ... on PrismicLevel2DataBodyQuote {
                          id
                          primary {
                            author
                            image {
                              alt
                              url
                              localFile {
                                publicURL
                              }
                            }
                            quote {
                              html
                            }
                          }
                          slice_type
                        }
                        ... on PrismicLevel2DataBodyTextblock {
                          id
                          primary {
                            text {
                              html
                            }
                            title1 {
                              text
                            }
                          }
                          slice_type
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      type
      url
      alternate_languages {
        document {
          ... on PrismicLearningDetail {
            url
          }
        }
        lang
      }
      lang
    }
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $locale } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
