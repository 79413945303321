import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { PropTypes } from "prop-types";

function isBrowser() {
  return typeof window !== "undefined";
}

export const CookieModal = ({ override }) => {
  const [overrideShow, setOverrideShow] = useState(override);
  const context = useContext(AppContext);
  const [bannerHidden, setBannerHidden] = useState(context.getCookieModalState);
  const [state, setState] = useState({
    acceptAnalytical: true,
    acceptPersonalisation: true,
  });
  const handleAnalytical = () => {
    setState({ ...state, acceptAnalytical: !state.acceptAnalytical });
  };
  const handlePersonalisation = () => {
    setState({ ...state, acceptPersonalisation: !state.acceptPersonalisation });
  };

  const handleAccept = (action) => {
    let cookiePrefsAnalytics = action === "all" ? true : state.acceptAnalytical;
    let cookiePrefsPersonalisation =
      action === "all" ? true : state.acceptPersonalisation;
    setBannerHidden(true);
    setOverrideShow(false);
    context.setCookiesState({
      analytics: cookiePrefsAnalytics,
      personalisation: cookiePrefsPersonalisation,
    });
  };

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  const key = isClient ? `client` : `server`;

  return (
    <>
      {isBrowser() && (!bannerHidden || overrideShow) && (
        <>
          <div className="c-modal__cookie-overlay" id="cookies" key={key}>
            <div className="c-modal c-modal__cookies -bottom-drawer@tablet  -medium ">
              <div className="c-modal__split-body">
                <div className="c-modal__split-body -left">
                  <h1>Cookies op vdab.be</h1>
                  <p>
                    VDAB gebruikt cookies om je surfervaring gemakkelijker en
                    aangenamer te maken en de inhoud van de site beter af te
                    stemmen op je behoeften en voorkeuren. Sommige cookies zijn
                    noodzakelijk om de website goed te laten werken. Deze kan je
                    niet weigeren. De andere cookies kies je zelf. 
                    <a href="/cookies">Meer info lees je in ons cookiebeleid</a>
                    .
                  </p>
                  <div className="form-container -bg-transparent">
                    <ul
                      id="eu-cookie-compliance-categories"
                      className="u-reset-list form-row"
                    >
                      <li className="c-modal__cookielist">
                        <label
                          htmlFor="cookie-category-essentieel"
                          className="checkbox c-modal__cookielabel"
                        >
                          <input
                            type="checkbox"
                            name="cookie-categories"
                            id="cookie-category-essentieel"
                            value="essentieel"
                            defaultChecked
                            disabled
                          />{" "}
                          Noodzakelijke cookies
                        </label>
                        <span className="u-ml15 u-text-meta" id="func">
                          Deze cookies zijn nodig voor je gebruiksgemak en om je
                          websitebezoek veilig en goed te laten verlopen. Je kan
                          ze niet weigeren.
                        </span>
                      </li>
                      <li className="c-modal__cookielist">
                        <label
                          htmlFor="cookie-category-analytisch"
                          className="checkbox c-modal__cookielabel"
                        >
                          <input
                            type="checkbox"
                            name="cookie-categories"
                            id="cookie-category-analytisch"
                            value="analytisch"
                            checked={state.acceptAnalytical}
                            onChange={handleAnalytical}
                          />
                          Analytische cookies
                        </label>
                        <span className="u-ml15 u-text-meta" id="func">
                          We gebruiken deze cookies om het bezoek aan onze
                          website te analyseren en verbeteren (Google). Wij
                          verwerken je gegevens anoniem in rapporten en
                          statistieken.
                        </span>
                      </li>
                      <li className="c-modal__cookielist">
                        <label
                          htmlFor="cookie-category-personalisation"
                          className="checkbox c-modal__cookielabel"
                        >
                          <input
                            type="checkbox"
                            name="cookie-categories"
                            id="cookie-category-personalisation"
                            value="personalisation"
                            checked={state.acceptPersonalisation}
                            onChange={handlePersonalisation}
                          />
                          Gepersonaliseerde dienstverlening op basis van je
                          surfgedrag
                        </label>
                        <span className="u-ml15 u-text-meta" id="func">
                          We gebruiken je surfgedrag op onze website om je een
                          gepersonaliseerde dienstverlening aan te bieden.
                        </span>
                      </li>
                    </ul>
                    <div>
                      <div className="c-divider"></div>
                      <div className="o-btn-group">
                        <button
                          onClick={() => handleAccept("all")}
                          className="c-btn -primary -medium agree-button eu-cookie-compliance-default-button"
                        >
                          Alle cookies aanvaarden
                        </button>
                        <button
                          onClick={() => handleAccept("save")}
                          className="c-btn -primary -medium eu-cookie-compliance-save-preferences-button"
                        >
                          Bewaar mijn keuze
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-modal__split-body -right">
                  <div className="c-modal__image-box">
                    <img
                      src="https://cdn.vdab.be/img/cookies.svg"
                      className="c-modal__image"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-modal__split-body -right">
            <div className="c-modal__image-box">
              <img
                src="https://cdn.vdab.be/img/cookies.svg"
                className="c-modal__image"
                alt="cookies"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

CookieModal.propTypes = {
  override: PropTypes.bool,
};
